import { createRouter, createWebHashHistory } from "vue-router"

const router = createRouter({
    history: createWebHashHistory(),
    routes: [{
        path: '/',
        component: () =>
            import ('../page/index')
    }, {
        path: '/user',
        component: () =>
            import ('../page/home')
    }, {
        path: '/ks',
        component: () =>
            import ('../page/ks')
    }, {
        path: '/bs',
        component: () =>
            import ('../page/bs')
    }, {
        path: '/wyy',
        component: () =>
            import ('../page/wyy')
    }, {
        path: '/hddddd',
        component: () =>
            import ('../page/hd')
    }, {
        path: '/dy',
        component: () =>
            import ('../page/dy')
    }]
})


export default router