<template>
  


	<router-view/>




  

</template>

<script>
import aes from './js/aes.js'
import axios from 'axios'
import Cookies from 'js-cookie'
import Watermark from "./js/watermark.js"
import { showToast,showDialog} from 'vant';



window.onload = function() {

	(function () {
    if (
      typeof WeixinJSBridge == "object" &&
      typeof WeixinJSBridge.invoke == "function"
    ) {
      handleFontSize();
    } else {
      if (document.addEventListener) {
        document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
      } else if (document.attachEvent) {
        document.attachEvent("WeixinJSBridgeReady", handleFontSize);
        document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
      }
    }
	
    function handleFontSize() {
      // 设置网页字体为默认大小
      WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
      // 重写设置网页字体大小的事件
      WeixinJSBridge.on("menu:setfont", function () {
        WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
      });
    }
  })()

    // 禁用双指放大
    document.addEventListener('touchstart', function(event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    });
    document.addEventListener('gesturestart', function(event) {
      event.preventDefault()
    })
    // 禁用双击放大
    var lastTouchEnd = 0;
    document.documentElement.addEventListener('touchend', function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
            event.preventDefault();
        }
        lastTouchEnd = now;
    }, {
        passive: false
    });
}
window.alert = function(name){
var iframe = document.createElement("IFRAME");
iframe.style.display="none";
iframe.setAttribute("src", 'data:text/plain');
document.documentElement.appendChild(iframe);
window.frames[0].window.alert(name);
iframe.parentNode.removeChild(iframe);
}
export default {
	
	data(){
    return{
      ipp:''
    }
   },

   components:{
      // tc
   },

   created() {
 
     axios.get('https://www.lixi.love/yhip.php').then(res=>{
      var data = res.data
      this.ipp = data
      }).catch(res=>{
      console.log(0);
      })

      if(this.getUrlParam("yqz")){
        this.setCookie('yqz',this.getUrlParam("yqz"),30)
      }else{
        this.setCookie('yqz',"oAl_h6g7wFNyITNroj0Kp9r14ocE",30)
      }


    axios.get('/h5/appid.php').then(res=>{
      
      if(res.data.code==1){

        if(!Cookies.get('yhid')){

var appid = res.data.appid;
var redirect = encodeURIComponent(window.location.href);
var wx_code = this.getUrlParam("code");
if (!wx_code) {
    window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + redirect + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
} else {
               
                var arr = {code:wx_code,ip:this.ipp,yqz:Cookies.get('yqz')}
                var data = {jz:'code',data:arr}
                axios.post('/h5/api.php',{cnm:'你干嘛哎呦',data:aes.jia(data)},{
                    headers:{
                        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    }
                }).then(res=>{
                  var data = JSON.parse(aes.jie(res.data))
                  if(data.code==200){
                      
                      this.setCookie('yhid',data.qiujie,30)
                      this.sx()
                     
                  }
                }).catch(res=>{
                    this.bccode('login',2)
                    showToast({message: '加载失败 请退出重新进入一下~',position: 'top'})
                })


}
}else{
if(Cookies.get('yhid')!=''){
                //  xcxid:Cookies.get('jizhi'),
                var arr = {h5id:Cookies.get('yhid')}
                var data = {jz:'login',data:arr}
                axios.post('/h5/api.php',{cnm:'你干嘛哎呦',data:aes.jia(data)},{
                    headers:{
                        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    }
                }).then(res=>{
                    var data = JSON.parse(aes.jie(res.data))
                    this.fx(data.fx)
                    if(data.code==200){
                        this.bccode('data',res.data)
                        this.bccode('login',1)
                        if (!Watermark) {
                          Watermark = null
                          return
                        }else{
                          Watermark.set(
                          'ID: '+data.data.id
                          )
                        }
                    }else if(data.code==201){
                        this.bccode('data',res.data)
                        this.bccode('login',0)
                    }else if(data.code==500){
                        this.bccode('data',res.data)
                        this.bccode('login',2)
                    }

                }).catch(res=>{
                    this.bccode('login',2)
                    
                })
  }else{
    this.bccode('login',0)
}
}

      }else if(res.data.code==2){
        showDialog({
              title: '提示',
              message: '停服更新中.. 添加新功能中\n\n预计'+res.data.msg+'之前更新完毕\n\n请更新完毕后再来使用 么么叽!',
              }).then(() => {
              window.close()
        })
      }else{
        showDialog({
                                title: '提示',
                                message: '请返回重新进入该页面',
                            }).then(() => {
                              window.close()
        })
      }






    }).catch(res=>{
      showDialog({
                                title: '提示',
                                message: '请返回重新进入该页面',
                            }).then(() => {
                              window.close()
        })
    })

   
    

	 },
 
mounted() {

  


},
   methods:{
    sx(){
      if(Cookies.get('yhid')!=''){
                    //  xcxid:Cookies.get('jizhi'),
                    var arr = {h5id:Cookies.get('yhid')}
                    var data = {jz:'login',data:arr}
                    axios.post('/h5/api.php',{cnm:'你干嘛哎呦',data:aes.jia(data)},{
                        headers:{
                            'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
                        }
                    }).then(res=>{
                        var data = JSON.parse(aes.jie(res.data))
                        this.fx(data.fx)
                        if(data.code==200){
                            this.bccode('data',res.data)
                            this.bccode('login',1)
                            if (!Watermark) {
                              Watermark = null
                              return
                            }else{
                              Watermark.set(
                              'ID: '+data.data.id
                              )
                            }
                        }else if(data.code==201){
                            this.bccode('data',res.data)
                            this.bccode('login',0)
                        }else if(data.code==500){
                            this.bccode('data',res.data)
                            this.bccode('login',2)
                        }

                    }).catch(res=>{
                        this.bccode('login',2)
                        
                    })
      }else{
        this.bccode('login',0)
    }
    },
    fx(data){
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名
        jsApiList: [
                "updateAppMessageShareDatam",
                "onMenuShareAppMessage",
                "openLocation",
                "onMenuShareTimeline"
            ] // 必填，需要使用的JS接口列表
    })

    wx.ready(function() {
        //需在用户可能点击分享按钮前就先调用
        wx.error(function(res) {
        })

        wx.onMenuShareAppMessage({
            title: data.bt, // 分享标题
            desc: data.ms, // 分享描述
            link: data.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: data.img, // 分享图标
            type: "", // 分享类型,music、video或link，不填默认为link
            dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
            success: function() {
              showToast({message: '分享成功',position: 'top'})
            },
            cancel: function() {
              showToast({message: '取消分享',position: 'top'})
            }
        })

        wx.onMenuShareTimeline({
            title: data.bt, 
            link: data.url,
            imgUrl: data.img,
            success: function() {
              showToast({message: '分享成功',position: 'top'})
            },
            cancel: function() {
              showToast({message: '取消分享',position: 'top'})
            }
        })
    })
    },
    
    // getUrlParam: function (name) {
    //    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    //    var r = window.location.search.substr(1).match(reg);
    //    if (r != null) return unescape(r[2]);
    //    return null;
    //   },

     getUrlParam(name) {
	    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
	   },

   

	
      
    bccode(key, value) {
	    localStorage.setItem(key, JSON.stringify(value));
	},
	 
     dqcode(key) {
	    const value = localStorage.getItem(key);
	    return value ? JSON.parse(value) : null;
	},

	sccode(key) {
	    localStorage.removeItem(key);
	},

	setCookie(name, value, days) {
  		let expires = '';
  		if (days) {
    	const date = new Date();
    	date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    	expires = '; expires=' + date.toUTCString();
  		}
  		document.cookie = name + '=' + (value || '') + expires + '; path=/';
	},


   }


}
</script>


<style>

</style>