let watermark = {}
let idd = "求解云应用"
let setWatermark = (str) => {
  let id = idd
  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id))
  }
 
  //创建一个画布
  let can = document.createElement("canvas")
  //设置画布的长宽
  can.width = 260
  can.height = 130                                                                 
 
  let cans = can.getContext("2d")
  //旋转角度
  cans.rotate((-15 * Math.PI) / 180)
  cans.font = "15px Vedana"
  //设置填充绘画的颜色、渐变或者模式
  cans.fillStyle = "rgba(200, 200, 200, 0.40)"
  //设置文本内容的当前对齐方式
  cans.textAlign = "center"
  //设置在绘制文本时使用的当前文本基线
  cans.textBaseline = "Middle"
  //在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
  cans.fillText(str, can.width / 3, can.height / 1)
  let div = document.createElement("div")
  div.id = id
  const styleStr = `
            position:fixed;
            visibility:visible !important;
            top:30px;
            width:${document.documentElement.clientWidth}px;
            height:${document.documentElement.clientHeight}px;
            left:0;
            z-index:100000;
            pointer-events:none;
            background:url('${can.toDataURL("image/png")}') left top repeat`
  div.setAttribute("style", styleStr)
  document.body.appendChild(div)

  return id;
}
 
// 该方法只允许调用一次
watermark.set = (str) => {
  let id = setWatermark(str)
  setInterval(() => {
    if (document.getElementById(id) === null) {
      id = setWatermark(str)
    }
  }, 500)
  window.onresize = () => {
    setWatermark(str)
  }
}
// 移除
const outWatermark = id => {
  if (document.getElementById(id) !== null) {
    const div = document.getElementById(id)
    div.style.display = "none"
  }
}
 
watermark.remove = () => {
  const str = idd
  outWatermark(str)
}
 
// 将 watermark 的控制方法挂载到 window 对象上
window.watermark = watermark
 
export default watermark