<template>


    <div v-show="MaskBe" class="MaskCss" >
    
        <div :class="['Popup',openBe?'openActive':'']">
        
            <div style="padding: 20px;">
                <div style="text-align: end;margin: -10px -10px -35px 0px;">
                      <img @click="closeFun()"  src="https://jz.jzapi.top/wenjian/icon/gb.gif" style="height: 30px;width: 30px;" />
                  </div>
                <div style="text-align: center;margin: 10px 0px;font-size: 15px;letter-spacing: 2px;font-weight: 700;color: slategrey;">{{bt}}</div>
                
                <div v-if="lxkf">
                    <div style="display: flex;text-align: center;flex-direction: column;align-items: center;margin-top: 20%;"> 
                        <div style="font-size: 15px;color: slategray;font-weight: 700;">长按识别下方二维码 添加客服vx</div>
                        <img style="width: 120px;height: 120px;margin: 20px 0px;" :src="kfewm" />
                        <div style="font-size: 13px;color: red;">客服在线时间 10:00-22:00</div>
                    </div>
                </div>

                <div v-if="login">
                    <div style="display: flex;flex-direction: column;padding: 0px 20px;align-items: center;text-align: center;"> 

                        <!-- <input v-model="id" class="ksid" type="text" placeholder="请粘贴 获取的ID" style="background: #f3f3f3;border: 1px solid #f3f3f3;padding: 8px 5px;width: 80%;text-align: center;border-radius: 10px;border-color: #f3f3f3;font-size: 13px;margin-top: 20px;"> -->
                        <div>
<img style="width: 60%;margin-top: 0px;"  src="https://wimg.588ku.com/gif620/21/10/04/023bd4ec83471c42cf8702d4e21db842.gif" />
</div>
                        <div @click="dl()" style="background: #3e72ff;width: 80%;margin-top: 20px;font-size: 13px;color: #fff;border-radius: 30px;padding: 8px 5px;font-weight: 700;">登 录</div>

                        <!-- <div @click="dljc()" style="color: #fff;margin-top: 20px;font-size: 12px;background: #ed6a0c;padding: 5px;border-radius: 30px;width: 80%;">点我查看 登录 视频教程</div>

                        <div style="color: #fff;margin-top: 20px;font-size: 12px;background: #14ac00;padding: 5px;border-radius: 30px;width: 80%;">1. 长按识别下方二维码 打开进入</div>
                        <img style="width: 100px;height: 100px;margin: 20px 0px;" :src="xcxewm" />
                        <div style="color: #fff;margin-top: 0px;font-size: 12px;background: #14ac00;padding: 5px;border-radius: 30px;width: 80%;">2. 进入领取卡密 复制&nbsp;&nbsp;&nbsp;粘贴登录</div> -->
                        <!-- <img style="margin: 20px 0px;width: 80%;" src="../img/dljc.jpg" /> -->
                    </div>
                </div>

            </div>

        </div>
    
    </div>

</template>

<script>
import aes from '../js/aes.js'
import axios from 'axios';
import Cookies from 'js-cookie'
import { showToast,showLoadingToast } from 'vant';
window.onload = function() {

(function () {
if (
  typeof WeixinJSBridge == "object" &&
  typeof WeixinJSBridge.invoke == "function"
) {
  handleFontSize();
} else {
  if (document.addEventListener) {
    document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
  } else if (document.attachEvent) {
    document.attachEvent("WeixinJSBridgeReady", handleFontSize);
    document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
  }
}

function handleFontSize() {
  // 设置网页字体为默认大小
  WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
  // 重写设置网页字体大小的事件
  WeixinJSBridge.on("menu:setfont", function () {
    WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
  });
}
})()

// 禁用双指放大
document.addEventListener('touchstart', function(event) {
  if (event.touches.length > 1) {
    event.preventDefault()
  }
});
document.addEventListener('gesturestart', function(event) {
  event.preventDefault()
})
// 禁用双击放大
var lastTouchEnd = 0;
document.documentElement.addEventListener('touchend', function (event) {
    var now = Date.now();
    if (now - lastTouchEnd <= 300) {
        event.preventDefault();
    }
    lastTouchEnd = now;
}, {
    passive: false
});
}
window.alert = function(name){
var iframe = document.createElement("IFRAME");
iframe.style.display="none";
iframe.setAttribute("src", 'data:text/plain');
document.documentElement.appendChild(iframe);
window.frames[0].window.alert(name);
iframe.parentNode.removeChild(iframe);
}
export default {
    data(){
        return{
            MaskBe:false,openBe:false,login:false,id:'',ipp:''
        }
    },
    created() {
        if(this.dqcode('data')){
        var data = JSON.parse(aes.jie(this.dqcode('data')))
        this.kfewm = data.data.kfewm
        this.xcxewm = data.data.xcxewm
        }
        axios.get('https://www.lixi.love/yhip.php').then(res=>{
      var data = res.data
      this.ipp = data
      }).catch(res=>{
      console.log(0);
      })
    },
	methods:{
        dljc(){
            var data = JSON.parse(aes.jie(this.dqcode('data')))
            window.location.href = data.data.dljc
        },
        setCookie(name, value, days) {
            let expires = '';
            if (days) {
          const date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = '; expires=' + date.toUTCString();
            }
            document.cookie = name + '=' + (value || '') + expires + '; path=/';
      },
        dl(){
            // if(this.id){
            //     if(this.id.length>15){
              
                    if(Cookies.get('yhid')){
                        showLoadingToast({
                        message: '登录中...',
                        forbidClick: true,duration: 0
                        });
                        // xcxid:this.id,
                        var arr = {h5id:Cookies.get('yhid'),ip:this.ipp,yqz:Cookies.get('yqz')}
                        var data = {jz:'reg',data:arr}
                        axios.post('/h5/api.php',{cnm:'你干嘛哎呦',data:aes.jia(data)},{
                        headers:{
                            'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
                        }
                        }).then(res=>{
                        var data = JSON.parse(aes.jie(res.data))
                        if(data.code==200){
                            this.setCookie('jizhi',this.id,30)
                            // this.setCookie('jizhi',this.id,30)
                            this.bccode('login',1)
                            showToast({message: '开始使用吧! 少爷公主'})
                            location.reload()
                        }else if(data.code==201){
                            // this.id = ''
                            // showToast({message: 'ID不存在 或 已使用',position: 'top'})
                            showToast({message: '登录失败 请返回重新进入试试!',position: 'top'})
                        }else if(data.code==500){
                            showToast({message: '请重试a',position: 'top'})
                        }

                        }).catch(res=>{
                        showToast({message: '服务器异常 请稍后重试~',position: 'top'})
                    })
                    }else{
                        
                        showToast({message: '请返回重新进入~',position: 'top'})
                        location.reload()
                    }

            //     }else{
            //     showToast({message: '少爷公主 请先获取ID',position: 'top'})
            //     }
            // }else{
            //     showToast({message: '少爷公主 请先填写ID',position: 'top'})
            // }
        },

        openFun(a){
            if(a==1){
                this.lxkf = true
                this.login = false
                this.bt = ''
            }else if(a==2){
                this.lxkf = false
                this.login = true
                this.bt = '公主少爷请先登录'
            }

            this.MaskBe=true
            setTimeout(() => {
                this.openBe=true
                }, 10);
		},
			
        bccode(key, value) {
          localStorage.setItem(key, JSON.stringify(value));
      },
       
      // 读取数据
       dqcode(key) {
          const value = localStorage.getItem(key);
          return value ? JSON.parse(value) : null;
      },
  
      sccode(key) {
          localStorage.removeItem(key);
      },
        setCookie(name, value, days) {
            let expires = '';
            if (days) {
          const date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = '; expires=' + date.toUTCString();
            }
            document.cookie = name + '=' + (value || '') + expires + '; path=/';
      },

			closeFun(){
				this.openBe=false
        setTimeout(() => {
         this.MaskBe=false
        }, 100);
			},
    }
}

</script>

<style scoped>
.openBtn{
		width: 100px;
		height: 40px;
		margin: 80px auto;
		text-align: center;
		line-height: 40px;
		border-radius: 4px;
		color: #fff;
		background: skyblue;
	}
	/* 弹出层默认样式 */
	.Popup{
		width: 100%;
		height: 75%;
		background: #fff;
		position: relative;
		bottom: -500px;
    max-width: 32rem;
		z-index: 1000;
		transition: all 0.1s linear; 
    border-radius: 20px 20px 0px 0px;
	}
	/* 点击按钮是将盒子 bottom 值归零即可实现弹出效果,
	同理，如需更改弹出方向只需将bottom改成top、left、right即可
	(默认样式的方向也需一起更改哦) */
	.openActive{
		bottom: 0px !important;
	}
	/* 遮罩层样式 */
	.MaskCss {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgb(0 0 0 / 19%);
		z-index: 1001;display: flex;
    justify-content: center;
    /* justify-items: center; */
    /* align-self: center; */
    align-items: end;
	}
    .ksid:focus{
          border: 1px solid #f3f3f3;
          background: #f3f3f3;
          border-color: #f3f3f3;
      }
</style>