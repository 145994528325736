import CryptoJS from "crypto-js";
// 加密函数
var key = CryptoJS.enc.Utf8.parse('sdf12543*985s41a');
var iv = CryptoJS.enc.Utf8.parse('eres12120ws+3s10');

export default{

jia(str) {
  var str = {
    token:this.sj(),
    data:str
  }
  
  var encrypted = CryptoJS.AES.encrypt(JSON.stringify(str), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
},
 
//解密
jie(str) {
        
        var decrypt = CryptoJS.AES.decrypt(str, key, { 
        iv:iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypt.toString(CryptoJS.enc.Utf8);
},


sj() {
        var currentTime = new Date();  // 创建Date对象
				var hours = currentTime.getHours() < 10 ? "0" + currentTime.getHours() : currentTime.getHours();  // 获取小时
				var minutes = currentTime.getMinutes() < 10 ? "0" + currentTime.getMinutes() : currentTime.getMinutes();  // 获取分钟
				var seconds = currentTime.getSeconds()+10;
				if(seconds>=60){
					var minutes = minutes+1 < 10 ? "0" + minutes+1 : minutes+1;
					var seconds = seconds = '00'
				}
				if(minutes>60){
					var hours = hours+1 < 10 ? "0" + hours+1 : hours+1
					var minutes = minutes = '00'
				}
				if(hours>=24){
					var hours = hours = '00'
				}
				return hours+':'+minutes+':'+seconds
},

} 